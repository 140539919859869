import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext"
import { useNavigate, useLocation } from "react-router-dom";

import { EuiProvider, EuiCallOut, EuiLink, EuiPanel, EuiForm, EuiFormRow, EuiSpacer, EuiFieldText, EuiFieldPassword, EuiButton, EuiTitle } from '@elastic/eui';

import { backendURL } from "../custom/backendURL";

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { setToken, setSpouse } = useContext(AuthContext)

  const navigate = useNavigate()

  // Grab State Information, usually coming from a redirect
  const location = useLocation();
  var {type, message, destination} = location.state != null ? location.state : {type: null, message: null, destination: null}

  const handleSubmit = async () => {
    try{
      // Try Logging in
      const loginPayload = {
        email: email,
        password: password
      }
      const response = await axios.post(backendURL + "/auth/login", loginPayload)
      setToken(response.data.token)
      setSpouse(response.data.spouse)
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", "{\"parent\": true, \"spouse\": " + response.data.spouse +", \"userInd\": 0}")
      
      if (destination != null){
        // If we redirected from a certain destination, go there
        navigate(destination)
      } else {
        // Else go to secure home page.
        navigate("/home")
      }
      
    } catch (error) {
      // Log in failed for whatever reason
      console.error("Authentication failed:", error);
      localStorage.removeItem("token");
      localStorage.removeItem("user")
      location.state = {type: "badLog", message: error.response.data, destination: destination}
      setPassword("")
    }
  };

  // Different callouts to display above login. Maybe none.
  function Callout(){
    if (type == "forbid"){
      return <EuiCallOut title={message} color="warning"/>
    } else if (type == "succReg"){
      return <EuiCallOut title={message} color="success"/>
    } else if (type == "badLog"){
      return <EuiCallOut title={message} color="danger"/>
    } else {
      return null
    }
  }

  const divStyle = {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px"
  }

  return (
    <EuiProvider>
      <div style={divStyle}>
      <EuiPanel>
        <EuiTitle><h1>Login</h1></EuiTitle>
        <Callout/>
        <EuiForm component="form">
          <EuiFormRow label="Email">
            <EuiFieldText placeholder="YourName@email.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength="64" type="email"/> 
          </EuiFormRow>
            
          <EuiFormRow label="Password">
            <EuiFieldPassword placeholder="Password123!" value={password} onChange={(e) => setPassword(e.target.value)} maxLength="32"/>
          </EuiFormRow>
          <EuiSpacer/>
          <EuiButton fill color="primary" onClick={handleSubmit} size="s" style={{ margin: 5}}>Login</EuiButton>
        </EuiForm>
        <EuiSpacer/>
        <EuiLink fill color="primary" href="/register">Register</EuiLink>
      </EuiPanel>
      </div>
    </EuiProvider>
    
  );
}
export default Login