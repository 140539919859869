import React, { useState } from "react";
import axios from "axios";

import { EuiCallOut, EuiForm, EuiFormRow, EuiSpacer, EuiFieldText, EuiFieldPassword} from '@elastic/eui';

import { MultistepForm } from "../custom/multistep";

import { useNavigate } from "react-router-dom";

import { backendURL } from "../custom/backendURL";

function RegisterForm() {
  // DEFINE VARIABLES FOR REGISTER FORM
  const [f_name, setf_name] = useState("")
  const [showFNameError, setShowFNameError] = useState(false)
  const [showLNameError, setShowLNameError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [l_name, setl_name] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate()

  // DEFINE HOW TO HANDLE SUBMITTING VARIABLES FOR REGISTER FORM
  const handleSubmit = async () => {
    // BIG IF BLOCK FOR VALID REGISTRATION
    var validRegister = true
    if (f_name == ""){
      validRegister = false
      setShowFNameError(true)
    } else {
      setShowFNameError(false)
    }

    if (l_name == ""){
      validRegister = false
      setShowLNameError(true)
    } else {
      setShowLNameError(false)
    }

    if (email == ""){
      validRegister = false
      setShowEmailError(true)
    } else {
      setShowEmailError(false)
    }

    if (password == ""){
      validRegister = false
      setShowPasswordError(true)
    } else {
      setShowPasswordError(false)
    }

    // More validity checks can go here.

    if (validRegister){
      const registerPayload = {
        f_name: f_name,
        l_name: l_name,
        email: email,
        password: password
      }
      try {
        const response = await axios.post(backendURL + "/auth/register", registerPayload)
        console.log("Success Response: " + response.data.message)
        navigate("/login", { state: {type: "succReg", message: "Successful Registration"}})
      } catch (error) {
        console.error("Registration failed:", error.response.data)
        setShowEmailError(true)
        setEmailError("Email in use already")
      }
    }
  };

  // DEFINE REGISTER FORM EUI ELEMENTS
  const stepOne = <EuiForm component="form">
    <EuiFormRow label="First Name" isInvalid={showFNameError}>
      <EuiFieldText placeholder="Jane" value={f_name} onChange={(e) => setf_name(e.target.value)} maxLength="32" isInvalid={showFNameError}/> 
    </EuiFormRow>
    <EuiFormRow label="Last Name" isInvalid={showLNameError}>
      <EuiFieldText placeholder="Smith" value={l_name} onChange={(e) => setl_name(e.target.value)} maxLength="32" isInvalid={showLNameError}/> 
    </EuiFormRow>
    <EuiFormRow label="Email" isInvalid={showEmailError} error={emailError}>
      <EuiFieldText placeholder="YourName@email.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength="64" type="email" isInvalid={showEmailError}/> 
    </EuiFormRow>
    <EuiFormRow label="Password" isInvalid={showPasswordError}>
      <EuiFieldPassword placeholder="Password123!" value={password} onChange={(e) => setPassword(e.target.value)} maxLength="32" isInvalid={showPasswordError}/>
    </EuiFormRow>
  </EuiForm>

  // Placeholder step for payment info
  const stepTwo = <EuiForm component="form"> <EuiCallOut title="Something Else Here" color="primary"></EuiCallOut> </EuiForm>

  // Email & Password Step
  const stepThree = <EuiForm>
    <EuiFormRow label="Email" isInvalid={showEmailError} error={emailError}>
      <EuiFieldText placeholder="YourName@email.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength="64" type="email" isInvalid={showEmailError}/> 
    </EuiFormRow>
      
    <EuiFormRow label="Password" isInvalid={showPasswordError}>
      <EuiFieldPassword placeholder="Password123!" value={password} onChange={(e) => setPassword(e.target.value)} maxLength="32" isInvalid={showPasswordError}/>
    </EuiFormRow>
  </EuiForm>

  // CREATE MULTISTEP REGISTER FORM
  const RegisterForm = MultistepForm([stepOne], handleSubmit)

  return (
    <div>
      {RegisterForm.step}
      <EuiSpacer/>
      {RegisterForm.Buttons()}
      <EuiSpacer/>
    </div>
  );
}

export default RegisterForm