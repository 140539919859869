import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Login from "./pages/Login"
import Register from "./pages/Register"
import HomePage from "./pages/HomePage"
import AccountPage from "./pages/Account"
import { AuthProvider } from "./pages/AuthContext";
import { AccProvider } from "./pages/AccContext";



function App() {
  return (
    <Router>
      <AuthProvider>
        <AccProvider>
          <Routes>
            <Route path="/login" element={<Login />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/home" element={<HomePage />}/>
            <Route path="/account" element={<AccountPage />}/>
            <Route path="*" element={<Navigate to="/login" replace />}/>
          </Routes>
        </AccProvider>
      </AuthProvider>
    </Router>
  );
}

export default App
