import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext"
import { AccContext } from "./AccContext"
import { Navigate } from "react-router-dom"


import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider, EuiText, EuiPanel, EuiButton, EuiLink, EuiFlyout, EuiFlyoutHeader, EuiTitle, EuiFlyoutBody } from '@elastic/eui';

import AddActivityForm from "../forms/ActivityForms"
import AddTransactionForm from "../forms/TransactionForms";
import SideNav from "../forms/SideNav";

function AccountPage() {
  const { token, loading, userParent, userInd} = useContext(AuthContext);
  const { acc_loaded, acc_synced, updateCurrChildAcc, childPendingDisplay, numKidsStatus, initializeAcc, SignedIn, childAccount, viewingind, childDetails, childdetails_loaded, childTransactionsDisplay, childActivitiesDisplay, accountList} = useContext(AccContext);

  const [showActForm, setShowActForm] = useState(false)
  const [showTransForm, setShowTransForm] = useState(false)
  const [showPendForm, setShowPendForm] = useState(false)

  // const [currview, setcurrview] = useState("activities")

  const ActFormFlyout = <EuiFlyout onClose={() => setShowActForm(false)}>
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size="m">
        <h2>Add Activity for Child</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody>
      <AddActivityForm/>
    </EuiFlyoutBody>
  </EuiFlyout>

  const TransFormFlyout = <EuiFlyout onClose={() => setShowTransForm(false)}>
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size="m">
        <h2>Add Transaction for Child</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody>
      <AddTransactionForm/>
    </EuiFlyoutBody>
  </EuiFlyout>

  const PendingFormFlyout  = <EuiFlyout onClose={() => setShowPendForm(false)}>
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size="m">
        <h2>Pending Transactions</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody>
      {childPendingDisplay()}
    </EuiFlyoutBody>
  </EuiFlyout>

  function addActivityButton(){
    if (userParent){
      return <EuiButton onClick={() => setShowActForm(true)} size="s" style={{ margin: 5}}>
        Add Activity?
      </EuiButton>
    }
  }

  // Show nothing while Token is loading
  if (loading) {
    return null
  }

  // REDIRECT IF USER IS UNAUTHENTICATED
  if (!token) {
    return <Navigate to="/login" state={{type:"forbid", message: "Please log in to view this page", destination:"/home"}} replace/>
  }

  if (!acc_loaded) {
    initializeAcc(token)
    if (!childdetails_loaded) {
      if (userParent){
        childDetails(token, viewingind)
      } else {
        childDetails(token, userInd)
      }
    }
  }

  

  // Regular page begins here.

  const divStyle = {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px"
  }

  return (
    /*
    {numKidsStatus() != "None" && <EuiPanel>
      {childActivitiesDisplay()}
      {addActivityButton()}
    </EuiPanel>}
    */
    <EuiProvider>
      <SideNav/>
      <div style={divStyle}>
        <EuiTitle size="l"><h1>Accounts</h1></EuiTitle>
        <EuiPanel>
            <EuiText>{SignedIn()}</EuiText>
        </EuiPanel>
        {showActForm && ActFormFlyout}
        {showTransForm && TransFormFlyout}
        {showPendForm && PendingFormFlyout}
        {numKidsStatus() != "None" && <EuiPanel>
            {userParent && accountList()}
            {acc_synced ? childAccount(viewingind) : <div>
                    <EuiText>Updating</EuiText>
                    <EuiText>Updating</EuiText>
                    <EuiText>Updating</EuiText>
                </div>}
        </EuiPanel>}

        

        {numKidsStatus() != "None" && <EuiPanel>
          {childTransactionsDisplay()}
          <EuiButton  onClick={() => setShowTransForm(true)} size="s" style={{ margin: 5}}>Add Transaction</EuiButton>
          {userParent && <EuiButton  onClick={() => setShowPendForm(true)} size="s" style={{ margin: 5}}>Show Pending Transactions</EuiButton>}
        </EuiPanel>}

        {numKidsStatus() == "None" && <EuiPanel>
            <EuiText>NO KIDS YET</EuiText>
        </EuiPanel>}

        <EuiPanel>
          <EuiLink color="primary" href="/home">Back Home</EuiLink>
        </EuiPanel>
      </div>
      
    </EuiProvider>
  )
    
}

export default AccountPage