import React, { useState, useContext } from "react";
import axios from "axios";

import { EuiCallOut, EuiForm, EuiFormRow, EuiFieldText, EuiCheckbox, EuiFieldNumber, EuiButton, EuiFlexGroup, EuiButtonIcon} from '@elastic/eui';

import { AccContext } from "../pages/AccContext"
import { AuthContext } from "../pages/AuthContext"

import { backendURL } from "../custom/backendURL";

function AddActivityForm(){
    const { token, loading } = useContext(AuthContext);
    const { viewingind, addActivity } = useContext(AccContext);

    const [all_kids, setall_kids] = useState(false)
    const [fix_amount, setfix_amount] = useState(true)
    const [amount, setamount] = useState(0)
    const [form_complete, setform_complete] = useState(false)

    function confirmAmount(amount){
        let amountString = amount.toString()
        var decCount = 0
        if (amountString.includes(".")){
            decCount = amountString.split(".")[1].length
        }
        if (amountString.includes('-') || decCount > 2 || amount > 999999.99){
            // INVALID BALANCE, OUR DATABASE CAN'T STORE
        } else {
            setamount(amount)
        }
    }
    const [positive, setpositive] = useState(true)
    const [activity, setactivity] = useState("Sample")

    function flipPositive(){
        setpositive(!positive)
    }
    

    async function handleSubmit(){
        let activityPayload = {
            activity: activity,
            fix_amount: fix_amount,
            amount: amount,
            positive: positive,
            all_kids: all_kids,
            index: viewingind,
            token: token
        }
        try {
            const response = await axios.post(backendURL + "/account/addactive", activityPayload)
            addActivity(response.data)
            setform_complete(true)
        } catch (error) {
            console.error("Add Activity failed:", error.response.data)
        }
    }

    let form = <EuiForm component="form">
            <EuiFormRow label="Activity">
                <EuiFieldText placeholder="What they did" value={activity} onChange={(e) => setactivity(e.target.value)} maxLength="64"/>
            </EuiFormRow>
            {/* <EuiFormRow label="Fixed Amount?">
                <EuiCheckbox id="FlexAmount" checked={fix_amount} onChange={(e) => setfix_amount(e.target.checked)}/>
            </EuiFormRow> */}
            {fix_amount && <EuiFormRow label={"Amount " + (positive ? "Earned" : "Lost")}>
                <EuiFlexGroup>
                    <EuiButtonIcon aria-label="positive" onClick={() => flipPositive()} iconType={positive ? "plus":"minus"} size="m"/>
                    <EuiFieldNumber value={amount} onChange={(e) => confirmAmount(e.target.value)} step={0.01} max={999999.99} min={0.00} append="$"/>
                </EuiFlexGroup>
            </EuiFormRow>}
            <EuiButton fill color="primary" onClick={handleSubmit} size="s" style={{ margin: 5}}>Submit</EuiButton>
        </EuiForm>

    if (loading) {
        return null
    }

    // If Token fails to load, render a big warning.
    if (!token) {
        return <EuiCallOut title="Something has gone very wrong." color="danger"></EuiCallOut>
    }

    if (!form_complete){
        return form;
    } else {
        return <div>
            <EuiCallOut title="Activity Added!" color="success"></EuiCallOut>
            <EuiButton color="primary" onClick={() => setform_complete(false)} size="s" style={{ margin: 5}}>Add another?</EuiButton>
            </div>
    }
    
}

export default AddActivityForm