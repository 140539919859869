import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext"
import { AccContext } from "./AccContext"
import { Navigate } from "react-router-dom"


import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider, EuiText, EuiPanel, EuiButton, EuiLink, EuiTitle} from '@elastic/eui';

import { SwapProfile } from "../custom/SwapProfile";

import AddChildForm from "../forms/AddChildForm"
import SideNav from "../forms/SideNav";

function HomePage() {

  const { token, loading, userParent } = useContext(AuthContext);
  const { acc_loaded, numKidsStatus, initializeAcc, SignedIn, actionItems} = useContext(AccContext);

  const [showform, setshowform] = useState(false)

  const MyProfileSwap = SwapProfile()

  // Show nothing while Token is loading
  if (loading) {
    return null
  }

  // REDIRECT IF USER IS UNAUTHENTICATED
  if (!token) {
    return <Navigate to="/login" state={{type:"forbid", message: "Please log in to view this page", destination:"/home"}} replace/>
  }

  if (!acc_loaded) {
    initializeAcc(token)
  }

  // Regular page begins here.

  const divStyle = {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px"
  }

  let showTheForm = () => {
    setshowform(true)
  }

  let addFirstKid = <div>
      <EuiText>You don't have any kids yet! Add your first?</EuiText>
      <EuiButton color="primary" onClick={showTheForm} size="s" style={{ margin: 5}}>Get Started</EuiButton>
    </div>

  let addAnotherKid = <div>
    <EuiText>Add another kid?</EuiText>
    <EuiButton color="primary" onClick={showTheForm} size="s" style={{ margin: 5}}>Get Started</EuiButton>
  </div>
   
  function KidForm() {
    if (userParent){
      if (numKidsStatus() == "None"){
        return <EuiPanel>
            {!showform && addFirstKid}
            {showform && <AddChildForm/>}
        </EuiPanel>
      } else if (numKidsStatus() == "More"){
        return <EuiPanel>
            {!showform && addAnotherKid}
            {showform && <AddChildForm/>}
        </EuiPanel>
      } else {
        return <EuiPanel>
          <EuiText>Max Children</EuiText>
        </EuiPanel>
      }
    } else {
      return <EuiPanel>
        <EuiText>Not Parent</EuiText>
      </EuiPanel>
    }
  }

  


  return (
    <EuiProvider>
      <SideNav/>
      <div style={divStyle}>
        <EuiTitle size="l"><h1>Home</h1></EuiTitle>
        <EuiPanel>
          <EuiText>{SignedIn()}</EuiText>
          <EuiButton onClick={MyProfileSwap.openModal} size="s" style={{ margin: 5}}>Swap Profile</EuiButton>
        </EuiPanel>
        {userParent && <EuiPanel>
          <EuiText>Action Items</EuiText>
          {actionItems()}
        </EuiPanel>}

        {KidForm()}

        <EuiPanel>
          <EuiLink color="primary" href="/account">View Accounts</EuiLink>
        </EuiPanel>
        {MyProfileSwap.showModal && MyProfileSwap.modal()}
      </div>
      
    </EuiProvider>
  )
    
}

export default HomePage