import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../pages/AuthContext"
import { AccContext } from "../pages/AccContext"
import moment from 'moment';

import { EuiText, EuiCallOut, EuiForm, EuiFormRow, EuiFieldText, EuiFieldNumber, EuiSelect, EuiDatePicker, EuiCheckbox, EuiFlyout, EuiFlyoutHeader, EuiTitle, EuiFlyoutBody, EuiButton} from '@elastic/eui';

import { MultistepForm } from "../custom/multistep";

import { useNavigate } from "react-router-dom";

import { backendURL } from "../custom/backendURL";

import AddActivityForm from "../forms/ActivityForms"

function AddChildForm() {
    // Lines 14 - 91: Variable Definitions with confirm functions for certain variables to verify they meet constraints.
    const { token, loading, updateToken} = useContext(AuthContext);
    const { setacc_loaded, addChildInd} = useContext(AccContext);
    const navigate = useNavigate()

    const [f_name, setf_name] = useState("")
    
    const [showFNameError, setShowFNameError] = useState(false)

    const [l_name, setl_name] = useState("")
    const [showLNameError, setShowLNameError] = useState(false)
    
    const [birthday, setBirthday] = useState(moment())
    const [pin_image, setPinImage] = useState("dog")

    const [acc_name, setacc_name] = useState("Child's Account")
    function updateF_Name(name){
        setf_name(name)
        setacc_name(name + "'s Account")
    }
    const [showAccNameError, setShowAccNameError] = useState(false)

    const [form_complete, setcomplete] = useState(false)

    const [showActForm, setShowActForm] = useState(false)
    const ActFormFlyout = <EuiFlyout onClose={() => setShowActForm(false)}>
        <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
            <h2>Add Activity for Child</h2>
        </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
        <AddActivityForm/>
        </EuiFlyoutBody>
    </EuiFlyout>

    //`balance` DECIMAL (10,2) unsigned
    const [balance, setBalance] = useState(5)
    function confirmBalance(bal) {
        let balString = bal.toString()
        var decCount = 0
        if (balString.includes(".")){
            decCount = balString.split(".")[1].length
        }
        if (balString.includes('-') || decCount > 2 || bal > 9999999999.99){
            // INVALID BALANCE, OUR DATABASE CAN'T STORE
        } else {
            setBalance(bal)
        }
    }

    // `spend_limit` DECIMAL (3,1) unsigned
    const [limit, setLimit] = useState(5.0)
    function confirmLimit(lim){
        let limString = lim.toString()
        var decCount = 0
        if (limString.includes(".")){
            decCount = limString.split(".")[1].length
        }
        if (limString.includes('-') || decCount > 1 || lim > 100.00){
            // INVALID BALANCE, OUR DATABASE CAN'T STORE
        } else {
            setLimit(lim)
        }
    }

    const [limitTime, setLimitTime] = useState("monthly")
    const [limitRoll, setLimitRoll] = useState(true)
    
    // `allowance_amount` DECIMAL(4,2) unsigned
    const [hasAllowance, setHasAllowance] = useState(false)
    const [allowanceVal, setAllowanceVal] = useState(2.00)
    function confirmAllowance(all) {
        let allString = all.toString()
        var decCount = 0
        if (allString.includes(".")){
            decCount = allString.split(".")[1].length
        }
        if (allString.includes('-') || decCount > 2 || all > 9999.99){
            // INVALID BALANCE, OUR DATABASE CAN'T STORE
        } else {
            setAllowanceVal(all)
        }
    }
    const [allowanceTime, setAllowanceTime] = useState("monthly")

    const pin_enum = [
        { value: "dog", text: "Dog" },
        { value: "cat", text: "Cat" },
        { value: "fish", text: "Fish" },
        { value: "bird", text: "Bird" },
        { value: "hamster", text: "Hamster" },
    ]

    const time_enum = [
        { value: "weekly", text: "Weekly"},
        { value: "biweekly", text: "Bi-Weekly"},
        { value: "monthly", text: "Monthly"},
    ]

    // While loading authentication token, return nothing
    if (loading) {
        return null
    }

    // If Token fails to load, render a big warning.
    if (!token) {
        return <EuiCallOut title="Something has gone very wrong." color="danger"></EuiCallOut>
    }

    const handleSubmit = async () => {
        // Form Validation - show errors if Text Fields are empty.
        var validAdd = true
        if (f_name == ""){
          validAdd = false
          setShowFNameError(true)
        } else {
          setShowFNameError(false)
        }
    
        if (l_name == ""){
          validAdd = false
          setShowLNameError(true)
        } else {
          setShowLNameError(false)
        }
    
        if (acc_name == ""){
          validAdd = false
          setShowAccNameError(true)
        } else {
          setShowAccNameError(false)
        }

        // Extra validation could go here.

        // Submit to backend.
        if (validAdd){
            let childPayload = {
                f_name: f_name,
                l_name: l_name,
                birthday: birthday,
                pin_image: pin_image,
                acc_name: acc_name,
                balance: balance,
                limit: {
                    percent: limit,
                    time: limitTime,
                    roll: limitRoll
                },
                allowance: {
                    has: hasAllowance,
                    value: allowanceVal,
                    time: allowanceTime
                },
                token: token
            }
    
            try {
                const response = await axios.post(backendURL + "/account/addchild", childPayload)
                console.log("Success Response: " + response.data.message)
                updateToken()
                addChildInd()
                setacc_loaded(false)
                setcomplete(true)
            } catch (error) {
                console.log(error)
                console.error("Add Child failed:", error.response.data)
            }
        }
    }

    const stepOne = <EuiForm component="form">
            <EuiFormRow label="Name" isInvalid={showFNameError || showLNameError}>
                <div>
                    <EuiFieldText placeholder="Jane" value={f_name} onChange={(e) => updateF_Name(e.target.value)} maxLength="32" isInvalid={showFNameError}/>
                    <EuiFieldText placeholder="Smith" value={l_name} onChange={(e) => setl_name(e.target.value)} maxLength="32" isInvalid={showLNameError}/> 
                </div>
            </EuiFormRow>
            <EuiFormRow label="Birthday">
                <EuiDatePicker selected={birthday} onChange={(date) => setBirthday(date)}/>
            </EuiFormRow>
            <EuiFormRow label="Pin">
                <EuiSelect options={pin_enum} value={pin_image} onChange={(e) => setPinImage(e.target.value)}/>
            </EuiFormRow>
        </EuiForm>

    const stepTwo = <EuiForm component="form">
            <EuiFormRow label="Account Name" isInvalid={showAccNameError}>
                <EuiFieldText placeholder="Jane's Account" value={acc_name} onChange={(e) => setacc_name(e.target.value)} maxLength="32" isInvalid={showAccNameError}/>
            </EuiFormRow>
            <EuiFormRow label="Initial Balance">
                <EuiFieldNumber value={balance} onChange={(e) => confirmBalance(e.target.value)} step={0.01} max={9999999999.99} min={0.00} append="$"/>
            </EuiFormRow>
            <EuiFormRow label="Monthly Spending Limit">
                <EuiFieldNumber value={limit} onChange={(e) => confirmLimit(e.target.value)} step={0.1} max={100.0} min={0.0} append="%"/>
            </EuiFormRow>
        </EuiForm>

    const stepThree = <EuiForm component="form">
            <EuiFormRow label="Spending Limit">
                <EuiFieldNumber value={limit} onChange={(e) => confirmLimit(e.target.value)} step={0.1} max={100.0} min={0.0} append="%"/>
            </EuiFormRow>
            <EuiFormRow label="Every Time">
                <EuiSelect options={time_enum} value={limitTime} onChange={(e) => setLimitTime(e.target.value)}/>
            </EuiFormRow>
            <EuiFormRow label="Rollover?">
                <EuiCheckbox id="SpendRollover" checked={limitRoll} onChange={(e) => setLimitRoll(e.target.checked)}/>
            </EuiFormRow>
        </EuiForm>

    const stepFour = <EuiForm component="form">
            <EuiFormRow label="Has Allowance">
                <EuiCheckbox id="HasAllowance" checked={hasAllowance} onChange={(e) => setHasAllowance(e.target.checked)}/>
            </EuiFormRow>
            {hasAllowance && 
            <EuiFormRow label="Allowance Amount">
                <EuiFieldNumber value={allowanceVal} onChange={(e) => confirmAllowance(e.target.value)} step={0.01} max={9999.99} min={0.00} append="$"/>
            </EuiFormRow> }
            {hasAllowance && <EuiFormRow label="Every Time">
                <EuiSelect options={time_enum} value={allowanceTime} onChange={(e) => setAllowanceTime(e.target.value)}/>
            </EuiFormRow>}
        </EuiForm>

    const childForm = MultistepForm([stepOne, stepTwo], handleSubmit)
    
    if (form_complete){
        // You should add some activities - ways for your child to earn (or lose!) money
        // <EuiButton onClick={() => setShowActForm(true)} size="s" style={{ margin: 5}}>
        //     Add Activity?
        // </EuiButton>
        return <div>
            {showActForm && ActFormFlyout}
            <EuiText>Child Successfully Added! </EuiText>
        </div>
    } else {
        return (
            <div>
                {childForm.step}
                {childForm.Buttons()}
            </div>
            );
    }
    
}

export default AddChildForm