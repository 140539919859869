import React, { useContext } from "react";
import { AuthContext } from "../pages/AuthContext"

import { EuiCollapsibleNav, EuiImage, EuiLink, EuiText, EuiHorizontalRule} from '@elastic/eui';

import logo from "../assets/FitBux.png"

function SideNav(){
    const { logOut } = useContext(AuthContext);

    // Known issue: Some default behavior of EuiCollapsibleNav causes it to enter an active mode covering the rest of page contents when Browser Window is too small.
    return <EuiCollapsibleNav isOpen={true} isDocked={true} size={240} onClose={() => console.log("This Shouldn't Happen")}>
        
        <EuiImage src={logo} alt="Fitbux Logo"></EuiImage>
        <div style={{ padding: 16}}>
            <EuiText> <EuiLink color="primary" href="/home">Home</EuiLink> </EuiText>
            <EuiText> <EuiLink color="primary" href="/account">Accounts</EuiLink> </EuiText>
            <EuiText>Messages</EuiText>
            <EuiText>Settings</EuiText>
        </div>
        <EuiHorizontalRule margin="s"/>
        <div style={{ padding: 16}}>
            <EuiText>Contact Us</EuiText>
            <EuiText>Refer a Friend</EuiText>
            <EuiText> <EuiLink color="primary" onClick={() => logOut()} href="/login">Log Out</EuiLink></EuiText>
        </div>
    </EuiCollapsibleNav>
}

export default SideNav