import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../pages/AuthContext"
import { AccContext } from "../pages/AccContext"

import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiForm, EuiFormRow, EuiButton, EuiSelect, EuiFieldPassword} from '@elastic/eui';

import { backendURL } from "./backendURL";

export function SwapProfile(){

    const { profiles } = useContext(AccContext)
    const { token, changeUser } = useContext(AuthContext)

    const [showModal, setShowModal] = useState(false)
    const [password, setPassword] = useState("")
    const [pin_image, setpin_image] = useState("dog")
    const [curr_page, setcurr_page] = useState("Profile")
    const [profile, setprofile] = useState("")
    const [bad_pass, setbad_pass] = useState(false)
    
    const closeModal = () => {
        setcurr_page("Profile")
        setShowModal(false)
        setbad_pass(false)
    }
    const openModal = () => setShowModal(true)

    const profile_enum = profiles()

    const pin_enum = [
        { value: "dog", text: "Dog" },
        { value: "cat", text: "Cat" },
        { value: "fish", text: "Fish" },
        { value: "bird", text: "Bird" },
        { value: "hamster", text: "Hamster" },
    ]

    function selectProfile(p){
        if (p == -1){
            setcurr_page("ParentSwap")
            setprofile(p)
        } else {
            setcurr_page("ChildSwap")
            setprofile(p)
        }
    }

    async function submitParent() {
        let swapPayload = {
            token: token,
            parent: true,
            password: password,
            index: 0
        }
        try {
            let result = await axios.post(backendURL + "/auth/profile", swapPayload)
            if (result.data.message == "success"){
                changeUser(true, 0)
                closeModal()
            }
        } catch (error) {
            setbad_pass(true)
            console.error("Swap Profile Failed:", error.response.data)
        }
        
    }

    async function submitChild() {
        let swapPayload = {
            token: token,
            parent: false,
            password: pin_image,
            index: profile
        }
        try {
            let result = await axios.post(backendURL+ "/auth/profile", swapPayload)
            if (result.data.message == "success"){
                changeUser(false, profile)
                closeModal()
            }
        } catch (error) {
            setbad_pass(true)
            console.error("Swap Profile Failed:", error.response.data)
        }
        
    }

    function back(){
        setcurr_page("Profile")
        setbad_pass(false)
    }

    let profileForm = <EuiForm>
        <EuiFormRow label="Who to swap to?">
            <EuiSelect options={profile_enum} value={profile} onChange={(e) => selectProfile(e.target.value)} hasNoInitialSelection={true} />
        </EuiFormRow>
    </EuiForm>

    let parentForm =  <EuiForm>
        <EuiFormRow label="Enter Password for Parent">
            <EuiFieldPassword placeholder="Password123!" value={password} onChange={(e) => setPassword(e.target.value)} isInvalid={bad_pass} maxLength="32"/>
        </EuiFormRow>
        <div> <EuiButton fill color="primary" onClick={back} size="s" style={{ margin: 5}}>Back</EuiButton><EuiButton fill color="primary" onClick={submitParent} size="s" style={{ margin: 5}}>Submit</EuiButton> </div>
    </EuiForm>

    let childForm = <EuiForm>
        <EuiFormRow label="Enter Pin for Child">
            <EuiSelect options={pin_enum} value={pin_image} onChange={(e) => setpin_image(e.target.value)} isInvalid={bad_pass}/>
        </EuiFormRow>
        <div> <EuiButton fill color="primary" onClick={back} size="s" style={{ margin: 5}}>Back</EuiButton><EuiButton fill color="primary" onClick={submitChild} size="s" style={{ margin: 5}}>Submit</EuiButton> </div>
    </EuiForm>
        
    let header = <EuiModalHeader>
            <EuiModalHeaderTitle>
                Swap Profile
            </EuiModalHeaderTitle>
        </EuiModalHeader>

    let footer = <EuiModalFooter>
        <EuiButton onClick={closeModal} fill>
            Close
        </EuiButton>
    </EuiModalFooter>

    function modal() {
        if (curr_page == "Profile"){
            return <EuiModal onClose={closeModal}>
                {header}
                <EuiModalBody>
                    
                    {profileForm}
                </EuiModalBody>
                {footer}
            </EuiModal>
        } else if (curr_page == "ParentSwap"){
            return <EuiModal onClose={closeModal}>
                {header}
                <EuiModalBody>
                    {parentForm}
                </EuiModalBody>
                {footer}
            </EuiModal>
        } else if (curr_page == "ChildSwap"){
            return <EuiModal onClose={closeModal}>
                {header}
                <EuiModalBody>
                    {childForm}
                </EuiModalBody>
                {footer}
            </EuiModal>
        }
    }

    return {
        modal,
        showModal,
        openModal,
    }
}