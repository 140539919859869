import React, { createContext, useContext, useState, useCallback } from "react"
import { AuthContext } from "./AuthContext"
import axios from "axios";
import { EuiText, EuiDataGrid, EuiSelect, EuiForm, EuiButton, EuiButtonIcon, EuiFlexGroup, EuiSplitPanel, EuiLink} from '@elastic/eui';

import { backendURL } from "../custom/backendURL";

export const AccContext = createContext()

export const AccProvider = ({ children }) => {

    const { token, userParent, userSpouse, userInd, loading } = useContext(AuthContext)

    const [acc_loaded, setacc_loaded] = useState(false)
    const [acc_synced, setacc_synced] = useState(true)
    const [parent_email, setp_email] = useState("")
    const [parent_first, setp_first] = useState("");
    const [parent_last, setp_last] = useState("");
    const [spouse_email, setspouse_email] = useState("")
    const [spouse_first, setspouse_first] = useState("");
    const [spouse_last, setspouse_last] = useState("")
    const [kid_limit, setkid_limit] = useState("")
    const [child_list, setchild_list] = useState([])
    const [child_accs, setchild_accs] = useState([])

    const [viewingind, setviewingind] = useState(0)
    const [childdetails_loaded, set_childdetails_loaded] = useState(false)
    const [activities, setactivities] = useState([])

    const [transactions, settransactions] = useState([])

    function pendingTransactions(){
        var pending = []
        var i = 0;
        while (i < transactions.length){
            if (transactions[i].status == "pending"){
                var temp = transactions[i]
                temp.index = i
                pending.push(temp)
            }
            i += 1
        }
        return pending
    }

    var idToAct = {}

    var columns = [
        {
            id: "date",
            schema: "datehiddentime",
            displayAsText: "Date",
            isSortable: true,
            initialWidth: 150,
            actions: {
                showHide: false,
                showMoveLeft: false,
                showMoveRight: false
            },
            defaultSortDirection: "asc",
        },
        {
            id: "amount",
            displayAsText: "Amount",
            actions: false,
            initialWidth: 100
        },
        {
            id: "description",
            displayAsText: "Description",
            actions: false,
            initialWidth: 180
        },
        {
            id: "status",
            displayAsText: "Status",
            actions: false,
            initialWidth: 180
        },
        {
            id: "entered_by",
            displayAsText: "Entered By",
            actions: false,
            initialWidth: 100
        }
    ]

    if (userParent){
        columns.push(
            {
                id: "delete",
                displayAsText: "Delete",
                actions: false,
                initialWidth: 60
            }
        )
    }

    const [visibleColumns, setVisibleColumns] = useState(
        columns.map(({ id }) => id) // initialize to the full set of columns
    );

    const [pagination, setPagination] = useState({ pageIndex: 0 });
    const onChangeItemsPerPage = useCallback(
        (pageSize) =>
        setPagination((pagination) => ({
            ...pagination,
            pageSize,
            pageIndex: 0,
        })),
        [setPagination]
    );
    const onChangePage = useCallback(
        (pageIndex) =>
        setPagination((pagination) => ({ ...pagination, pageIndex })),
        [setPagination]
    );

    const storeData = []
    const [data, setData] = useState(storeData);
    const [sortingColumns, setSortingColumns] = useState([
        { id: "date", direction: "asc" }
    ]);
    const setSorting = (sortingColumns) => {
        const sortedData = [...data].sort((a, b) => {
          for (let i = 0; i < sortingColumns.length; i++) {
            const column = sortingColumns[i];
            const aValue = a[column.id];
            const bValue = b[column.id];
    
            if (aValue < bValue) return column.direction === "asc" ? -1 : 1;
            if (aValue > bValue) return column.direction === "asc" ? 1 : -1;
          }
    
          return 0;
        });
    
        setData(sortedData);
        setSortingColumns(sortingColumns);
      };

    const RenderCellValue = ({ rowIndex, columnId }) => {
        if (transactions[rowIndex] == "DELETED"){
            return "Deleted"
        }
        if (columnId == "date"){
            return new Date(transactions[rowIndex][columnId]).toDateString()
        }
        if (columnId == "amount"){
            return (transactions[rowIndex].positive == "true" ? "+$" : "-$") + Number(transactions[rowIndex][columnId]).toLocaleString("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2})
        }
        if (columnId == "delete"){
            return <EuiButtonIcon aria-label="delete" iconType="trash" onClick={() => deleteTransaction(transactions[rowIndex].id, rowIndex)}/>
        }
        if (columnId == "status" && userParent){
            return <EuiFlexGroup>
                <EuiText>{transactions[rowIndex][columnId]}</EuiText>
                <EuiButtonIcon aria-label="approve" iconType="check" onClick={() => transactionStatus(transactions[rowIndex].id, rowIndex, "approved")}/>
                <EuiButtonIcon aria-label="deny" iconType="cross" onClick={() => transactionStatus(transactions[rowIndex].id, rowIndex, "denied")}/>
            </EuiFlexGroup>
        }
        return transactions[rowIndex][columnId]
    };

    async function initializeAcc(myToken){
        if (loading){

        } else {
            let infoPayload = {
                token: myToken
            }
            try {
                const response = await axios.post(backendURL + "/account/info", infoPayload)
                setp_first(response.data.parent.first_name)
                setp_last(response.data.parent.last_name)
                setp_email(response.data.parent.email)
                setkid_limit(response.data.parent.kidLimit)
                setspouse_email(response.data.parent.email_spouse)
                setspouse_first(response.data.parent.spouse_first)
                setspouse_last(response.data.parent.spouse_last)
                setchild_list(response.data.children)
                setchild_accs(response.data.accounts)
                setacc_loaded(true)
            } catch (error){
                console.error("Get Account Info failed:", error.response.data)
            }
            
        }
    }

    async function childDetails(myToken, ind){
        try {
            if (loading){

            } else {
                let detailsPayload = {
                    token: myToken,
                    ind: ind
                }
                const response = await axios.post(backendURL + "/account/details", detailsPayload)
                setviewingind(ind)
                set_childdetails_loaded(false)
                settransactions(response.data.transactions.tx)
                setactivities(response.data.activities.types)
                var i = 0;
                while (i < response.data.activities.types.length){
                    idToAct[response.data.activities.types[i].id] = response.data.activities.types[i].activity
                    i += 1
                }
                recalcBalance()
                set_childdetails_loaded(true)
                console.log("Child Details initialized")
            }
            
        } catch (error) {
            console.error("Get Child Details failed:", error.response.data)
        }
        
    }

    function profiles(){
        let result = [
            {value: -1, text: parent_first + " " + parent_last + " (Parent)"}
        ]
        let i = 0;
        while (i < child_list.length){
            result.push({value: i, text: child_list[i].first + " " + child_list[i].last + " (Child)"})
            i += 1;
        }

        return result
    }

    function accountList(){
        if (child_list.length <= 1){
            return null
        } else {
            let account_enum = []
            let i = 0;
            while (i < child_list.length){
                account_enum.push({value: i, text: child_accs[i].acc_name})
                i += 1;
            }
            return <EuiForm>
                <EuiSelect options={account_enum} value={viewingind} onChange={(e) => childDetails(token, e.target.value)}/>
            </EuiForm>
        } 
    }

    function numKids(){
        return child_list.length
    }



    function actionItems(){
        let actions = []
        var i = 0;
        while (i < child_list.length){
          if (child_list[i].pendingTrans){
            let string = child_list[i].first + " has a pending transaction"
            let ind = i
            actions.push(<EuiFlexGroup key={i + "a"}>
                <EuiText>{string}</EuiText>
                <EuiLink href="/account" onClick={() => {
                    setviewingind(ind)
                    }}>View</EuiLink>
                </EuiFlexGroup>)
          }
          if (child_list[i].pendingMsg){
            let string = "You have a message from " + child_list[i].first
            actions.push(<EuiText key={i + "b"}>{string}</EuiText>)
          }
          i += 1
        }
        if (actions.length == 0){
            actions.push("No pending actions!")
        }
        return actions;
    }

    function numKidsStatus(){
        if (child_list.length == 0){
            return "None"
        } else if (kid_limit == "Four" && child_list.length >= 4){
            return 'Max'
        } else if (kid_limit == "One" && child_list.length >= 1){
            return "Max"
        } else {
            return "More"
        }
    }
    
    function addActivity(activity){
        if (childdetails_loaded){
            activities.push(activity)
            idToAct[activity.id] = activity.activity
        }
    }

    function SignedIn(){
        if (!token || !acc_loaded){
            return "Not currently signed in"
        } else {
            if (userParent && userSpouse){
                return "Currently signed in as " + spouse_first + " " + spouse_last + " (Parent)"
            } else if (userParent) {
                return "Currently signed in as " + parent_first + " " + parent_last + " (Parent)"
            } else {
                if (userInd < child_list.length){
                    return "Currently signed in as " + child_list[userInd].first + " " + child_list[userInd].last + " (Child)"
                } else {
                    return "Bad Child Index"
                }
                
            }
        }
    }
    
    function childAccount(ind){
        if (acc_loaded && token){
            if (ind < child_accs.length){
                let account = child_accs[ind]
                return <div>
                    <EuiText>{account.acc_name}</EuiText>
                    <EuiText>Current Balance: ${Number(account.balance).toLocaleString("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</EuiText>
                    <EuiText>Current Spending Limit: ${Number(account.curr_limit).toLocaleString("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2})} </EuiText>
                </div>
            } else {
                return <EuiText>Bad Child Index</EuiText>
            }
        } else {
            return <EuiText>Not signed in</EuiText>
        }
    }

    function updateCurrChildAcc(newDeets){
        child_accs[viewingind] = newDeets
    }

    function getactivity(i){
        return activities[i]
    }

    async function deleteActivity(id, index){
        let deletePayload = {
          id: id,
          token: token
        }
        setacc_synced(false)
        let result = await axios.post(backendURL + "/account/delactivity", deletePayload)
        activities.splice(index, 1)
        alert("Activity Deleted")
        setacc_synced(true)
      }
    
    async function deleteTransaction(id, index){
        let deletePayload = {
            id: id,
            token: token
        }
        setacc_synced(false)
        let result = await axios.post(backendURL + "/account/deltransaction", deletePayload)
        transactions.splice(index, 1)
        alert("Transaction Deleted")
        recalcBalance()
        
    }

    async function transactionStatus(id, index, status){
        if (transactions[index].status !=  status){
            let statusPayload = {
                id: id,
                status: status,
                token: token,
            }
            transactions[index].status = status
            let result = await axios.post(backendURL + "/account/transactionstatus", statusPayload)
            setacc_synced(false)
            recalcBalance()
        }
    }

    function childActivitiesOptions(){
        var options = []
        options.push({value: -5, text: "Earned"})
        options.push({value: -4, text: "Gifted"})
        options.push({value: -3, text: "Interest"})
        options.push({value: -2, text: "Spent"})
        options.push({value: -1, text: "Fine"})
        var i = 0
        while (i < activities.length){
            if (activities[i].ind == viewingind){
                options.push({value: i, text: activities[i].activity})
            }
            i += 1
        }
        return options;
    }

    function childActivitiesDisplay(){
        if (!token || !acc_loaded || !childdetails_loaded) {
            return <EuiText>Not Currently Signed In</EuiText>
        } else {
            return <div>
                <EuiText>{child_list[viewingind].first + " " + child_list[viewingind].last + "'s activities"}</EuiText>
                <div>
                {activities.map((activity, index) => 
                    activity.ind == viewingind ?
                    <div key={index} style={{ width: '80%', display: 'grid', gridTemplateColumns: '2fr 1fr', margin: '5px'}}>
                        <EuiText>
                            {activity.activity + " for " + (!activity.fix_amount ? "flexible amount" : ((activity.positive ? "+$" : "-$") + activity.amount))}
                        </EuiText>
                        {userParent &&
                        <EuiButton size="s" onClick={() => deleteActivity(activity.id, index)} style={{width: '60px'}}>
                            Delete
                        </EuiButton>}
                    </div>
                    :
                    null
                    ) 
                }
                </div>
            </div>
        }
    }

    async function recalcBalance(){
        let recalcPayload = {
          index: viewingind,
          token: token
        }
        let result = await axios.post(backendURL + "/account/recalc", recalcPayload)
        updateCurrChildAcc(result.data)
        setacc_synced(true)
    }

    function childTransactionsDisplay(){
        if (!token || !acc_loaded || !childdetails_loaded) {
            return <EuiText>Not Currently Signed In</EuiText>
        } else {
            return <div>
                <EuiText>{child_list[viewingind].first + " " + child_list[viewingind].last + "'s transactions"}</EuiText>
                <EuiDataGrid 
                    columns = {columns}
                    columnVisibility={{ visibleColumns, setVisibleColumns }}
                    rowCount = {transactions.length}
                    renderCellValue={RenderCellValue}
                    toolbarVisibility={false}
                    inMemory={{ level: "sorting" }}
                    sorting={{ columns: sortingColumns, onSort: setSorting }}
                    schemaDetectors={[
                        {
                          type: 'datehiddentime',
                          // Try to detect if column data is this schema. A value of 1 is the highest possible. A (mean_average - standard_deviation) of .5 will be good enough for the autodetector to assign.
                          detector(value) {
                            return 0
                          },
                          // How we should sort data matching this schema. Again, a value of 1 is the highest value.
                          comparator(a, b, direction) {
                            const aValue = new Date(a);
                            const bValue = new Date(b);
                            if (aValue < bValue) return direction === 'asc' ? 1 : -1;
                            if (aValue > bValue) return direction === 'asc' ? -1 : 1;
                            return direction === 'asc' ? -1 : 1;
                          },
                          // Text for what the ASC sort does.
                          sortTextAsc: 'New-Old',
                          // Text for what the DESC sort does.
                          sortTextDesc: 'Old-New',
                          // EuiIcon or Prop to signify this schema.
                          icon: 'star',
                          // The color to use for the icon prop.
                          color: '#000000',
                        },
                        ]}
                    pagination = {{
                        ...pagination,
                        onChangeItemsPerPage: onChangeItemsPerPage,
                        onChangePage: onChangePage,
                    }}
                />
            </div>
            
        }
    }

    function childPendingDisplay(){
        if (!token || !acc_loaded || !childdetails_loaded) {
            return <EuiText>Not Currently Signed In</EuiText>
        } else {
            let pending = pendingTransactions()
            if (pending.length == 0){
                return <EuiText>No Pending Transactions!</EuiText>
            }
            return <div>
                {pending.map((transaction, index) =>
                    <EuiSplitPanel.Outer>
                        <EuiSplitPanel.Inner>
                            <EuiFlexGroup>
                                <EuiText>{transaction.description}</EuiText>
                                <div>
                                    <EuiText>{(transaction.positive == "true" ? "+" : "-") + transaction.amount}</EuiText>
                                    <EuiText>{transaction.date.slice(0,10)}</EuiText>
                                </div>
                            </EuiFlexGroup>
                        </EuiSplitPanel.Inner>
                        <EuiSplitPanel.Inner>
                            <EuiButtonIcon aria-label="approve" iconType="check" onClick={() => transactionStatus(transaction.id, transaction.index, "approved")} size="m"/>
                            <EuiButtonIcon aria-label="deny" iconType="cross" onClick={() => transactionStatus(transaction.id, transaction.index, "denied")} size="m"/>
                        </EuiSplitPanel.Inner>
                    </EuiSplitPanel.Outer>
                )}
            </div>
        }
    }

    function addTransaction(id, transaction){
        let temp = transactions
        let newTrans = {}
        //newTrans.aid = transactions[0].aid
        newTrans.id = id
        newTrans.amount = Number(transaction.amount).toFixed(2)
        let date = transaction.date.toDate()
        newTrans.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        newTrans.description = transaction.description
        newTrans.entered_by = "child"
        newTrans.status = "pending"
        if (transaction.parent){
            newTrans.entered_by = "parent"
            newTrans.status = "approved"
        }
        newTrans.positive = "" + transaction.positive
        newTrans.tid = transaction.type
        if (sortingColumns[0]['direction'] == "asc"){
            temp.splice(0, 0, newTrans)
        } else {
            temp.push(newTrans)
        }     
        settransactions(temp)
        recalcBalance()
    }

    function addChildInd(){
        setviewingind(child_list.length)
    }

    return (
        <AccContext.Provider value={
            {   
                acc_loaded,
                acc_synced,
                viewingind,
                childdetails_loaded,
                actionItems,
                setacc_loaded,
                numKids,
                numKidsStatus,
                initializeAcc,
                updateCurrChildAcc,
                SignedIn,
                profiles,
                childAccount,
                childActivitiesOptions,
                childActivitiesDisplay,
                childTransactionsDisplay,
                childDetails,
                addActivity,
                getactivity,
                accountList,
                addTransaction,
                childPendingDisplay,
                addChildInd}
        }>
            {children}
        </AccContext.Provider>
    )
}