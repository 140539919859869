import { useState } from "react";

import { EuiButton } from '@elastic/eui';

// Quick Multistep Form Wrapper
// Takes a list of Step Objects, which should be JSX HTML
// And a reference to the submit function to be called on clicking submit.
export function MultistepForm(steps, submit) {
    const [currStepInd, setCurrStepInd] = useState(0)

    function next() {
        setCurrStepInd(i => {
            if (i >= steps.length - 1) return i
            return i + 1
        })
    }

    function back() {
        setCurrStepInd(i => {
            if (i <= 0) return i
            return i - 1
        })
    }

    function goto(ind){
        setCurrStepInd(ind);
    }

    // Returns a Div Back/Next/Submit Buttons appropriate to the current step.
    function Buttons(){
        if (currStepInd == 0 && currStepInd == steps.length - 1){
            return <div> <EuiButton fill color="primary" onClick={submit} size="s" style={{ margin: 5}}>Submit</EuiButton> </div>
        } else if (currStepInd == 0){
            return <div> <EuiButton fill color="primary" onClick={this.next} size="s" style={{ margin: 5}}>Next</EuiButton> </div>
        } else if (currStepInd == steps.length - 1){
            return <div> <EuiButton fill color="primary" onClick={this.back} size="s" style={{ margin: 5}}>Back</EuiButton><EuiButton fill color="primary" onClick={submit} size="s" style={{ margin: 5}}>Submit</EuiButton> </div>
        } else {
            return <div> <EuiButton fill color="primary" onClick={this.back} size="s" style={{ margin: 5}}>Back</EuiButton><EuiButton fill color="primary" onClick={this.next} size="s" style={{ margin: 5}}>Next</EuiButton> </div>
        }
    }

    return {
        currStepInd,
        step: steps[currStepInd],
        steps,
        goto,
        next,
        back,
        Buttons
    }
 }