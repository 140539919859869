import React, { createContext, useState, useEffect} from "react"
import axios from "axios";

import { backendURL } from "../custom/backendURL";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userParent, setParent] = useState(true)
    const [userSpouse, setSpouse] = useState(false)
    const [userInd, setInd] = useState(0)

    useEffect(() => {
        const storedToken = localStorage.getItem("token")
        var storedUser = localStorage.getItem("user")
        try {
            storedUser = JSON.parse(storedUser)
            setParent(storedUser.parent)
            setSpouse(storedUser.spouse)
            setInd(storedUser.userInd)
        } catch {
            // Something went wrong parsing user   
        }
        setToken(storedToken);
        setLoading(false);
    }, [])

    function changeUser(parent, ind){
        localStorage.setItem("user", "{\"parent\": " + parent + ", \"spouse\": " + userSpouse +", \"userInd\": " + ind + "}")
        setParent(parent)
        setInd(ind)
    }

    async function updateToken(){
        let updatePayload = {
            token: token
        }
        let response = await axios.post(backendURL + "/auth/update", updatePayload)
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token)
    }

    function logOut(){
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }

    
    return (
        <AuthContext.Provider value={
            {   token, 
                setToken, 
                loading, 
                userParent,
                userSpouse, 
                setSpouse,
                userInd, 
                changeUser,
                updateToken,
                logOut}
        }>
            {children}
        </AuthContext.Provider>
    )
}

