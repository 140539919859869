import React from "react";

import RegisterForm from "../forms/RegisterForm"

import { EuiProvider, EuiLink, EuiPanel, EuiTitle} from '@elastic/eui'

function Register() {

    const divStyle = {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px"
    }

    return (
    <EuiProvider>
        <div style={divStyle}>
            <EuiPanel>
                <EuiTitle><h1>Register</h1></EuiTitle>
                <RegisterForm/>
                <EuiLink fill color="primary" href="/login">Login</EuiLink>
            </EuiPanel>
        </div>
        
    </EuiProvider> )
}

export default Register