import React, { useState, useContext } from "react";
import axios from "axios";
import moment from 'moment';

import { EuiCallOut, EuiForm, EuiFormRow, EuiSelect, EuiFieldNumber, EuiButton, EuiTextArea, EuiButtonIcon, EuiFlexGroup} from '@elastic/eui';

import { AccContext } from "../pages/AccContext"
import { AuthContext } from "../pages/AuthContext"

import { backendURL } from "../custom/backendURL";

function AddTransactionForm(){
    const { token, loading, userParent } = useContext(AuthContext);
    const { viewingind, childActivitiesOptions, getactivity, addTransaction } = useContext(AccContext);

    const [date, setdate] = useState(moment())
    const [amount, setamount] = useState(0)
    function confirmAmount(amount){
        let amountString = amount.toString()
        var decCount = 0
        if (amountString.includes(".")){
            decCount = amountString.split(".")[1].length
        }
        if (amountString.includes('-') || decCount > 2 || amount > 999999.99){
            // INVALID BALANCE, OUR DATABASE CAN'T STORE
        } else {
            setamount(amount)
        }
    }
    const [isfixed, setisfixed] = useState(false)
    const [positive, setpositive] = useState(true)
    const [description, setdescription] = useState("Earned")
    const [type, settype] = useState(-3)

    function flipPositive(){
        setpositive(!positive)
    }

    const [form_complete, setform_complete] = useState(false)

    const typeOptions = childActivitiesOptions()

    function selectType(ind){
        settype(ind)
        
        let others = ["Earned", "Gifted", "Interest", "Spent", "Fine"]
        if (ind < 0){
            setisfixed(false)
            setpositive(true)
            setamount(0)
            setdescription(others[Number(ind)+5])
        } else {
            let activity = getactivity(ind)
            setisfixed(activity.fix_amount)
            setpositive(activity.positive)
            setamount(activity.amount)
            setdescription(activity.activity)
        }
    }

    async function handleSubmit(){
        let transactionPayload = {
            amount: amount,
            positive: positive,
            date: date,
            description: description,
            type: type,
            parent: userParent,
            index: viewingind,
            token: token
        }
        if (type >= 0){
            let activity = getactivity(type)
            transactionPayload = {
                amount: amount,
                positive: positive,
                date: date,
                description: description,
                type: activity.id,
                parent: userParent,
                index: viewingind,
                token: token
            }
        }
        
        try {
            const response = await axios.post(backendURL + "/account/addtransaction", transactionPayload)
            console.log(response.data)
            addTransaction(response.data.id, transactionPayload)
            setform_complete(true)
        } catch (error) {
            console.error("Add Transaction failed:")
            console.error(error)
        } 
        
    }

    let form = <EuiForm component="form">
            <EuiFormRow label="Which activity?">
                <EuiSelect options={typeOptions} value={type} onChange={(e) => selectType(e.target.value)}/>
            </EuiFormRow>
            <EuiFormRow label="Description">
                <EuiTextArea placeholder="What they did" value={description} onChange={(e) => setdescription(e.target.value)} maxLength="255"/>
            </EuiFormRow>
            <EuiFormRow label={"Amount " + (positive ? "Earned" : "Lost")}>
                <EuiFlexGroup> 
                    <EuiButtonIcon aria-label="positive" onClick={() => flipPositive()} iconType={positive ? "plus":"minus"} disabled={isfixed} size="m"/>
                    <EuiFieldNumber value={amount} onChange={(e) => confirmAmount(e.target.value)} step={0.01} max={999999.99} min={0.00} append="$" disabled={isfixed}/>
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiButton fill color="primary" onClick={handleSubmit} size="s" style={{ margin: 5}}>Submit</EuiButton>
        </EuiForm>

    if (loading) {
        return null
    }

    // If Token fails to load, render a big warning.
    if (!token) {
        return <EuiCallOut title="Something has gone very wrong." color="danger"></EuiCallOut>
    }

    if (!form_complete){
        return form;
    } else {
        return <div>
            <EuiCallOut title="Transaction Added!" color="success"></EuiCallOut>
            <EuiButton color="primary" onClick={() => setform_complete(false)} size="s" style={{ margin: 5}}>Add another?</EuiButton>
            </div>
    }
}

export default AddTransactionForm